import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const Contact = () => (
  <Layout>
    <SEO title="Contact" />
    <div className="max-w-[90%] lg:max-w-[70vw] mx-auto small-height md:big-height">
      <p className="max-w-full mt-24 font-sans text-l mb-7">
        DM me anytime on Twitter&nbsp;
        <a
          href="https://twitter.com/gurjotsingh4398"
          target="_blank"
          rel="noopener noreferrer"
          className="font-semibold no-underline border-current text-primary-color hover:text-primary-color focus:text-primary-color hover:border-b-2 focus:border-b-2"
        >
          @gurjotsingh4398
        </a>
        &nbsp;or Linkedin&nbsp;
        <a
          href="https://www.linkedin.com/in/gurjotsingh4398/"
          target="_blank"
          rel="noopener noreferrer"
          className="font-semibold no-underline border-current text-primary-color hover:text-primary-color focus:text-primary-color hover:border-b-2 focus:border-b-2"
        >
          @gurjotsingh4398
        </a>
      </p>
    </div>
  </Layout>
);

export default Contact;
